<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" :label-width="110" inline="">
      <FormItem label="项目/企业" prop="projectId" v-if="!form.id">
        <Input v-model="form.projectName" disabled placeholder="项目/企业"> </Input>
      </FormItem>
      <FormItem label="服务计划" prop="policyPlanId">
        <Select v-model="form.policyPlanId" clearable placeholder="服务计划" transfer style="width: 100%;" @on-change="planChange" @on-clear="planClear">
          <Option v-for="(item, index) in subPlanList" :value="item.id" :key="item.id" :disabled="item.checkId > 0 && item.checkId !== form.id">[{{ item.checkId > 0 ? '已关联' : '未关联' }}]{{ item.title || `服务计划明细${index + 1}` }}</Option>
        </Select>
      </FormItem>
      <FormItem label="任务名称" prop="iname">
        <Input v-model="form.iname" placeholder="任务名称"></Input>
      </FormItem>
      <FormItem label="服务类型" prop="subType" v-if="type>2&&type!==7&&type!==9">
        <Select v-model="form.subType" placeholder="服务类型" v-if="type===4">
          <Option v-for="(item,index) in baseData['安全教育类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
        <Select v-model="form.subType" placeholder="服务形式" v-else-if="type===3">
          <Option v-for="(item,index) in baseData['应急演练类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
        <Select v-model="form.subType" placeholder="服务形式" v-else-if="type===6">
          <Option v-for="(item,index) in baseData['科技推广应用类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
        <Select v-model="form.subType" placeholder="服务形式" v-else-if="type===8">
          <Option v-for="(item,index) in baseData['其他事件类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="地址/网址" prop="address" style="width: 100%" v-if="type>2&&type!==7&&type!==9">
        <Input v-model="form.address" style="width: 100%" placeholder="地址/网址"></Input>
      </FormItem>
      <FormItem label="计划服务时间" prop="serveTime">
        <ys-date-picker style="width: 100%" :disabledDate="serveTimeDisabledDate" placeholder="检查时间" v-model="form.serveTime"/>
      </FormItem>
      <FormItem label="截止完成日" prop="finishAt">
        <ys-date-picker style="width: 100%" :disabledDate="finishAtDisabledDate" placeholder="截止完成日" v-model="form.finishAt"/>
      </FormItem>
      <FormItem label="含资料检查" prop="hasDoc" v-if="form.projectId>0&&(type===1||type===2)">
        <i-switch v-model="form.hasDoc" :true-value="1" :false-value="0">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
        <Poptip trigger="hover" placement="top-start" title="检查资料" width="600">
          <a href="javascript:" style="margin-left: 20px;">查看检查资料</a>
          <div slot="content">
            <Alert type="warning">请先选择检查项目！</Alert>
            <ys-page-list
                ref="table"
                :header="false"
                keyLabel="docCategoryId"
                size="small"
                :footer="false"
                :multiColumn="false"
                :tableData="docList"
                tableHeight="300"
                :headerColumns="headerColumns"/>
          </div>
        </Poptip>
      </FormItem>
      <FormItem label="开启审核" prop="isCheckReport">
        <Tooltip content="开启后该任务的整改单和报告都需要组长审核！" max-width="600">
          <i-switch v-model="form.isCheckReport" :true-value="1" :false-value="0">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </Tooltip>
      </FormItem>
      <FormItem label="检查标准" prop="warehouseId" v-if="type<3" style="width: 100%">
        <Select v-model="form.warehouseId" :disabled="form.status>1" @on-change="getStandardInfo" placeholder="检查标准">
          <Option v-for="(item) in standardList" :value="item.id" :key="item.id"> {{ item.iname }}</Option>
        </Select>
      </FormItem>
      <!-- 默认全选 -->
      <FormItem label="检查项目" prop="categoryList" style="width: 100%" v-if="type<3">
        <CheckboxGroup v-model="form.categoryList" @on-change="checkCategory">
          <Checkbox v-for="(item) in categoryList" :disabled="form.status > 1 || form.checkGroupId > 0" class="checkbox-border-item" border :label="item.id" :key="item.id"> {{ item.iname }}</Checkbox>
        </CheckboxGroup>
      </FormItem>
      <FormItem label="任务描述" prop="remarks" style="width: 100%">
        <Input v-model="form.remarks" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 5}" placeholder="输入任务描述"></Input>
      </FormItem>

      <h1 class="h1Title">检查专家</h1>
      <div v-for="(item,index) in form.expertList" :key="index">
        <FormItem label="专家姓名" style="width: 37%; display: inline-block" :prop="'expertList.'+index+'.iname'">
          <Input disabled v-model="item.iname" placeholder="专家姓名"/>
        </FormItem>
        <FormItem label="组长" :labelWidth="50" style="width: 12%; display: inline-block" :prop="'expertList.'+index+'.isLeader'">
          <i-switch :disabled="lastLeader&&item.isLeader===1" v-model="item.isLeader" :true-value="1" :false-value="0">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </FormItem>
        <FormItem label="本次费用" :labelWidth="90" style="width: 44%; display: inline-block" :prop="'expertList.'+index+'.cost'">
          <InputNumber v-model="item.cost" placeholder="本次费用" :min="0" style="width: 100%"/>
        </FormItem>
        <Button :disabled="form.expertList.length === 1" icon="ios-close-circle-outline" @click="delExpert(index)" style="margin-left: 5px;margin-top:1px;"></Button>
      </div>
    </Form>
    <div class="modalEditBtnArea">
      <Button :disabled="butflag" type="primary" @click="save('saveAndRelease')">保存并发布</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="projectFlag" :width="1200" title="选择项目">
    </ys-modal>
    <ys-modal v-model="expertFlag" :width="1200" title="选择专家">
      <MyExperts @on-ok="getExpertData"></MyExperts>
    </ys-modal>
  </div>
</template>

<script>
import edit from '@/mixins/edit'
import {getStandardInfo, getStandardData, getDoc} from "@/api/common";
import {checkInfo, checkSelectExpert} from "@/api/check";
import {servePlanList, subPlanList} from "@/api/plan";
import {post, getBaseData} from "ys-admin";

export default {
  name: 'taskEdit',
  mixins: [edit],
  components: {
    'MyExperts': () => import('@/components/MyExperts.vue'),
  },
  props: {
    planData: {type: Array, default: () => []},
    type: {type: Number, default: 2},
  },
  data() {
    return {
      ruleValidate: {
        projectId: [
          {required: true, message: '请选择需检查项目', trigger: 'blur', type: 'number'}
        ],
        iname: [
          {required: true, message: '请填写任务名称', trigger: 'blur'}
        ],
        warehouseId: [
          {required: true, message: '请选择检查标准', trigger: 'blur', type: 'number'}
        ],
        categoryList: [
          {required: true, message: '请至少选择一项检查项目', trigger: 'blur', type: 'array'}
        ]
      },
      headerColumns: [
        {width: 60, title: '排序', key: 'index', align: 'center'},
        {minWidth: 400, title: '需检查资料', key: 'name', align: 'left',},
      ],
      projectFlag: false,
      expertFlag: false,
      planFlag: false,
      subPlanFlag: false,
      standardList: [],
      docList: [],
      categoryList: [],
      expertsList: [],
      planList: [],
      subPlanList: [],
      projectData: [],
      form: {
        iname: '',
        isCheckReport: 0,
        hasDoc: 0,
        subType: '',
        address: '',
        policyOrgId: '',
        ServeOrgId: '',
        warehouseId: '',
        projectId: '',
        projectName: '',
        id: '',
        remarks: '',
        serveTime: new Date(new Date().getTime() + 1000 * 3600 * 7),
        finishAt: new Date(new Date().getTime() + 30 * 24 * 3600 * 1000),
        categoryList: [],
        policyId: '',
        expertList: [],
        servePlanId: '', // 总计划id
        policyPlanId: '', // 子计划id
      }
    }
  },
  computed: {
    serveTimeDisabledDate() {
      return (date) => {
        return date && date.valueOf() > new Date(this.form.finishAt).valueOf();
      }
    },
    finishAtDisabledDate() {
      return (date) => {
        return date && date.valueOf() < new Date(this.form.serveTime).valueOf() - 1000 * 60 * 60 * 24;
      }
    },
    lastLeader() {
      return this.form.expertList.filter(item => item.isLeader === 1).length === 1
    },
    butflag() {
      // 当前计划是当前用户组长才可新建任务
      const planData = this.subPlanList.filter(plan => plan.id === this.form.policyPlanId)
      return planData.length > 0 ? planData[0].leaderId !== this.userInfo.expert.id : true
    }
  },
  async mounted() {
    this.form.subType = parseInt(this.type + '1');
    await this.getStandard()
    await this.getInfo()
  },
  methods: {
    async getInfo() {
      if (this.mid) { // 编辑
        await checkInfo({id: this.mid}).then(res => {
          Object.keys(this.form).forEach((item) => {
            switch (item) {
              case 'categoryList':
                this.form.categoryList = (res.data.categoryList || []).map((item) => item.id);
                break;
              case 'expertList':
                this.form.expertList = res.data.expertList || []
                break;
              default:
                this.form[item] = res.data[item]
                break;
            }
          })
        })
        await this.getDocByProjectId()
        await this.getStandardInfo()
        await this.getSubPlanList()
      } else { // 新增
        this.form.type = this.type;
        if (this.planData.length > 0) {
          await this.getData(this.planData)
          this.form.policyPlanId = this.planData[0].id
          this.form.policyOrgId = this.planData[0].orgId;
          this.form.servePlanId = this.planData[0].servePlanId
        } else {
          this.form.warehouseId = this.standardList.length > 0 ? this.standardList[0].id : ''
          await this.getStandardInfo()
        }
      }
    },
    // 检查标准
    async getStandard() {
      if (this.type > 2) return;
      await getStandardData({limit: -1, status: 1}).then((res) => {
        this.standardList = res.data.list || [];
        this.form.warehouseId = this.form.warehouseId||(this.standardList.length > 0 ? this.standardList[0].id : '');
      })
    },
    // 选择检查标准下检查项目
    checkCategory(data) {
      if (this.type === 1) this.form.categoryList = data.length <= 0 ? [] : [data[data.length - 1]]
    },
    // 检查标准下检查项目
    async getStandardInfo(event) {
      // 手动切换检查标准时先清空数据
      if (this.type > 2) return;
      if (event) {
        this.categoryList = []
        this.form.categoryList = []
      }
      await getStandardInfo({id: this.form.warehouseId, random: Math.ceil(Math.random() * 100)}).then(res => {
        this.categoryList = res.data.categoryList || [];
        // 默认编辑时当切换后再自动全选，或者新增时直接自动全选
        if (this.type === 2 && (event || !this.mid)) this.form.categoryList = this.categoryList.map((item) => item.id);
      })
    },
    // 检查资料
    async getDocByProjectId() {
      if (!this.form.projectId) return
      await getDoc({projectId: this.form.projectId}).then(res => {
        this.docList = res.data || [];
      })
    },
    // 服务计划列表
    async getPlanList() {
      await servePlanList({orgId: this.userInfo.org_id, policyId: this.form.policyId}).then(res => {
        // 总服务计划id
        this.form.servePlanId = res.data?.list ? res.data.list[0].id : ''
      })
    },
    // 服务计划明细列表
    async getSubPlanList() {
      subPlanList({orgId: this.userInfo.org_id, policyId: this.form.policyId, type: this.planData[0].type, limit: -1}).then(res => {
        // 将当前组长下的计划筛选出来
        this.subPlanList = res.data.list.filter(item => item.leaderId === this.userInfo.expert.id) || []
        if (this.subPlanList.length > 0) {
          // 默认选中第一个没有关联任务的计划
          const subPlan = this.subPlanList.filter(item => item.checkId === 0)
          this.form.policyPlanId = !this.form.policyPlanId ? (subPlan.length > 0 ? subPlan[0].id : this.subPlanList[0].id) : this.form.policyPlanId
          this.form.servePlanId = !this.form.servePlanId ? (subPlan.length > 0 ? subPlan[0].servePlanId : this.subPlanList[0].servePlanId) : this.form.servePlanId
          if (!this.mid) this.planChange(this.form.policyPlanId);
        } else {
          // 当无服务子计划时，要调用一下查询服务总计划获取到总计划id(查询是否存在服务总计划)
          this.getPlanList()
        }
      })
    },
    planChange() {
      const planInfo = this.subPlanList.find(plan => this.form.policyPlanId > 0 && plan.id === this.form.policyPlanId);
      if (!planInfo) return;
      this.form.serveTime = planInfo.startAt || new Date(new Date().getTime() + 1000 * 3600 * 7);
      this.form.finishAt = planInfo.finishAt || new Date(new Date().getTime() + 30 * 24 * 3600 * 1000);
      this.form.iname = getBaseData('子计划检查类型', this.type).name + '任务-' + planInfo.title;
      // 只有待发布时切换服务计划方可修改以下数据（检查标准、检查项目、检查专家）
      if (this.form.status > 1) return
      this.form.warehouseId = planInfo.warehouseId || this.form.warehouseId || ''; // 兼容判断一下若计划中未携带标准则不用更改标准id
      if (this.type <= 2 && this.form.warehouseId > 0 && this.standardList.every(item => item.id !== this.form.warehouseId)) {
        this.$Notice.warning({
          title: '请知悉',
          desc: '当前计划所绑定的检查标准已被停用，已自动切换'
        });
        this.form.warehouseId = this.standardList.length > 0 ? this.standardList[0].id : '';
      }
      const expertList = planInfo.expertList || []
      expertList.push({
        iname: planInfo.leaderName,
        isLeader: 1,
        expertId: planInfo.leaderId
      });
      this.getExpertData(expertList.reverse())
      // 根据最新warehouseId获取到最新的检查项目
      this.getStandardInfo()
    },
    // 清空已选中计划,重置
    planClear() {
      this.form.serveTime = new Date(new Date().getTime() + 1000 * 3600 * 7);
      this.form.finishAt = new Date(new Date().getTime() + 30 * 24 * 3600 * 1000);
      this.form.servePlanId = ''
      this.form.policyPlanId = ''
    },
    async getData(data) {
      this.form.iname = ''
      this.form.expertList = []
      this.projectData = data
      this.form.projectId = data[0].projectId;
      this.form.projectName = data[0].projectName||data[0].enterpriseName;
      this.form.policyId = this.planData.length > 0 ? data[0].policyId : data[0].id;
      this.form.servePlanId = this.planData.length > 0 ? this.form.servePlanId : ''
      this.form.policyPlanId = this.planData.length > 0 ? this.form.policyPlanId : ''
      await this.getDocByProjectId()
      await this.getSubPlanList()
    },
    delExpert(index) {
      this.form.expertList.splice(index, 1)
      if (this.form.expertList.length > 0 && !this.form.expertList.some(item => item.isLeader === 1)) this.form.expertList[0].isLeader = 1;
    },
    getExpertData(data) {
      data.forEach((item) => {
        if (!this.form.expertList.some(expertItem => expertItem.expertId === item.id || expertItem.expertId === item.expertId)) {
          this.form.expertList.push({
            expertId: item.id || item.expertId,
            isLeader: item.isLeader || 0,
            expertName: item.iname,
            iname: item.iname,
            cost: null,
          })
        }
      });
      if (this.form.expertList.length > 0 && !this.form.expertList.some(item => item.isLeader === 1)) this.form.expertList[0].isLeader = 1;
    },
    // 发布任务
    publicTask(data) {
      checkSelectExpert({id: data.id, noExpert: true, serveTime: data.serveTime}).then(res => {
        if (res.code === 200) {
          this.close();
          this.$Notice.success({
            title: "提示！",
            desc: '操作成功！'
          });
          this.$emit('on-ok', res.data);
        } else {
          this.modal.spinShow = false;
        }
      }).catch(() => {
        this.modal.spinShow = false;
      })
    },
    save(mode) {
      this.modal.spinShow = true;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          post(this.form.id ? 'check/Edit' : 'check/Add', this.form).then((res) => {
            if (res.code === 200) {
              if (mode === 'saveAndRelease') {
                this.publicTask(res.data)
              } else {
                this.close();
                this.$Notice.success({
                  title: "提示！",
                  desc: '操作成功！'
                });
                this.$emit('on-ok', res.data);
              }
            }
          }).catch(() => {
            this.modal.spinShow = false;
          })
        } else {
          this.$Notice.error({
            title: "操作失败！",
            desc: '请检查表单是否填项正确！'
          });
          this.modal.spinShow = false;
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
.checkbox-border-item {
  margin-bottom: 10px;
  padding: 0 10px;
}

</style>
